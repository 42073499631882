@import 'styles/vars';
@import 'styles/colors';

.container {
  gap: 16px;
  @extend %flex-align;

  @media (max-width: $desktop) {
    flex-direction: column;
  }

  &_is_auth {
    @media (max-width: $desktop) {
      flex-direction: column;
    }
  }

  &__logout {
    border-radius: 8px;
  }

  &__button {
    max-width: max-content;

    @media (max-width: $desktop) {
      max-width: unset;
    }
  }

  &__image {
    width: 100%;
    padding: 7px;
    max-width: 48px;
    max-height: 48px;
    border-radius: 8px;
    color: $secondary-1600;
    background-color: $secondary-30;
    @extend %flex-align;

    &_img,
    & > div {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }
  }
}

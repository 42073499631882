@import 'styles/vars';
@import 'styles/colors';
@import 'styles/typography';

.wrapper {
  gap: 16px;
  width: 100%;
  display: flex;
  max-width: 1252px;
  padding: 20px 24px;
  border-radius: 16px;
  background-color: $secondary-600;

  @media (max-width: $desktop) {
    flex-direction: column;
  }

  &__container {
    gap: 16px;
    display: flex;
  }

  &__item {
    gap: 4px;
    @extend %flex-column-between;

    @media (max-width: $desktop) {
      align-items: center;
      flex: 1 1;
    }

    &__number {
      color: #00c6ff;
      @extend %heading-3;
    }

    &__text {
      color: $secondary-1500;
      @extend %body2-r;

      @media (max-width: $desktop) {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        text-align: center;
      }
    }
  }

  &__line {
    min-width: 1px;
    max-width: 1px;
    background-color: $secondary-100;

    &__horizontal {
      min-height: 1px;
      max-height: 1px;
      background-color: $secondary-100;
      width: 100%;
    }
  }
}

@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

.wrapper {
  padding: 24px 32px;
  max-width: 635px;

  & > div {
    padding: 0;
  }

  &__subtitle {
    gap: 32px;
    text-align: center;
    margin-bottom: 32px;
    color: $primary-1900;
    @extend %heading-4;
    @extend %flex-column-center;
    @include svgFill($secondary-1200, 128px);

    &__enabled {
      @include svgFill($success-75);
    }
  }

  &__icon {
    margin-bottom: 0;
  }
}

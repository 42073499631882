@import 'styles/vars';
@import 'styles/colors';
@import 'styles/typography';

$tablet-m: 576px;

.wrapper {
  padding: 0;
  max-width: 640px;
  border: 1px solid $secondary-1100;

  &__title {
    padding: 20px 32px;
    @extend %heading-4;

    &__container {
      margin-bottom: 0;
    }

    @media (max-width: $tablet-m) {
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      padding: 12px 16px;
    }
  }

  &__content {
    padding-right: 0;
    overflow-y: unset;
    max-height: unset;

    &__container {
      height: 100%;
      display: flex;
      position: relative;
      justify-content: center;

      &_cropper {
        width: 100%;
        height: 300px;
        position: relative !important;
      }
    }
  }

  &__image {
    width: 100%;
    max-height: 480px;
    min-height: 480px;
    position: relative;
    background-color: $background-60;

    @media (max-width: $tablet-m) {
      height: 100%;
      max-height: 320px;
      min-height: 320px;
    }

    & > div:nth-child(1) {
      position: absolute;
      width: 100%;
      height: 100%;

      img {
        width: 100%;
      }
    }

    :nth-child(2) {
      border: 1px solid $secondary-2000;

      :nth-child(1) {
        :nth-child(5)::after,
        :nth-child(7)::after,
        :nth-child(9)::after,
        :nth-child(11)::after {
          border-radius: 50%;
          background-color: #f8f8f8;
        }
      }
    }
  }

  &__container {
    width: 100%;
    padding: 20px 32px;
    @extend %flex-justify-between-center;

    @media (max-width: $tablet-m) {
      gap: 8px;
      padding: 20px 16px 12px;
      flex-direction: column-reverse;
    }

    &__cancel {
      width: 118px;

      @media (max-width: $tablet-m) {
        width: 100%;
      }
    }

    &__block {
      gap: 16px;
      @extend %flex-align;

      @media (max-width: $tablet-m) {
        width: 100%;
        justify-content: space-between;
      }

      &__rotate {
        width: 48px;
      }

      &__save {
        width: 102px;

        @media (max-width: $tablet-m) {
          width: 100%;
        }
      }
    }
  }
}

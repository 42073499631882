.card {
  background: #081320;
  padding: 16px;
  border-radius: 16px;
  display: flex;
  gap: 16px;
  position: relative;
  height: 312px;
  cursor: pointer;

  @media (max-width: 568px) {
    height: auto;
    flex-direction: column;
  }

  &__content {
    flex: 1;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 568px) {
      max-width: 100%;
      flex: unset;
    }

    &_title {
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      color: #e6f4ff;
      margin-bottom: 4px;
      word-wrap: break-word;
      overflow: hidden;
    }

    &_subtitle {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: #9fa8b3;
      word-wrap: break-word;
      overflow: hidden;
    }

    &_category {
      margin: 52px 0 16px;
      border-radius: 12px;
      max-width: 100%;
      overflow: hidden;

      @media (max-width: 568px) {
        img {
          width: 100%;
        }
      }
    }

    &_date {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: #576779;
      }

      a {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        color: #0095fa;
        display: flex;
        align-items: center;

        svg {
          width: 20px;
          height: 20px;

          path {
            fill: #0095fa;
          }
        }
      }
    }
  }

  &__image {
    flex: 1;
    overflow: hidden;
    border-radius: 8px;

    @media (max-width: 568px) {
      height: 50vw;
      flex: unset;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__lock {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #060f1ad9;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    &_icon {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      border: 2px solid #6f7d8c;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    svg {
      width: 34px;
      height: 40px;
    }
  }
}

@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

.wrapper {
  padding: 0;
  max-width: 938px;
  max-height: 686px;
  overflow: hidden;
  transition: all 0.6s;
  border: 1px solid $secondary-1100;

  @media (max-width: $tablet) {
    max-height: unset !important;
    max-width: unset !important;
    width: 100%;
    height: 100svh;
    border-radius: 0;
    min-height: -webkit-fill-available;
  }

  &__modal {
    @media (max-width: $tablet) {
      padding: 0;
    }
  }

  &__content {
    width: 100%;
    max-height: unset;
    padding: 0;
    display: flex;
    transition: all 0.6s;
    border-radius: 8px;

    @media (max-width: $tablet) {
      max-height: unset !important;
      max-width: unset !important;
      height: 100%;
    }
  }

  &__left {
    padding: 32px 20px;
    position: relative;
    min-width: 248px;
    overflow: hidden;
    max-width: 248px;
    border-radius: 8px 0 0 8px;
    background-color: $secondary-700;
    @extend %flex-column-between;

    &__header {
      gap: 8px;
      z-index: 1;
      @extend %flex-column;

      &__text {
        color: $secondary-1600;
        @extend %micro-lt;
      }
    }

    &__footer {
      color: $secondary-1500;
      z-index: 1;
      @extend %micro-r;

      &__terms {
        padding: 0 2px;
        color: $secondary-1300;
        display: inline-block;
        @extend %micro-r;

        &:hover {
          text-decoration: none;
        }
      }

      &__link {
        padding: 0 2px;
        color: $primary-10;
        display: inline-block;
        @extend %micro-r;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &__logo {
      position: absolute;
      left: -120px;
      bottom: -120px;
      @include svgFill($secondary-400, 265px);
    }
  }

  &__right {
    width: 100%;
    gap: 24px;
    overflow: hidden;
    @extend %flex-column;

    @media (max-width: $tablet) {
      gap: 16px;
      height: 100%;
      justify-content: space-between;
    }
  }
}

@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/vars';
@import 'styles/typography';

.wrapper {
  left: 0;
  gap: 8px;
  bottom: 0;
  z-index: 10;
  width: 100%;
  position: fixed;
  padding: 8px 16px;
  background-color: $secondary-500;
  @extend %flex-center;

  &__item {
    width: 100%;
    max-width: 66px;
    height: 48px;
    border-radius: 16px;
    transition: 0.8s all;
    @extend %flex-column-center;
    @include svgFill($secondary-1600);

    &:hover &__soon {
      animation: shake 0.82s 0.3s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: 1000px;
    }

    &__when_soon {
      gap: 4px;
    }

    &__soon {
      margin-top: -26px;
      max-width: 24px;

      p {
        padding-left: 0;
      }
    }

    &__active {
      background-color: $secondary-900;
      @include svgFill($primary-10);
    }
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(1px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-1.5px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(1.5px, 0, 0);
  }
}

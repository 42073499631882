@import 'styles/vars';
@import 'styles/typography';
@import 'styles/colors';
@import 'styles/mixins';

.wrapper {
  width: max-content;
  min-height: 24px;
  border-radius: 24px;
  padding: 4px 12px;
  @extend %flex-center;

  &__dynamic {
    padding: 0;
    width: 78px;
    overflow: hidden;
    transition: all 1s;
  }

  &__closed {
    width: 24px;
  }

  &__title {
    white-space: nowrap;
    text-transform: uppercase;
    padding-left: 4px;
    @extend %micro-sb;
    line-height: unset;

    &__dynamic {
      width: calc(100% - 36px);
      transition: 1s all;
      overflow: hidden;
    }

    &__active {
      padding-left: 0;
      width: 0;
    }
  }

  &__soon {
    background-color: $coming-soon-item-bg;
    @include svgSize(16px, 16px);
    @include svgFill($coming-soon-item-text-svg);

    &__title {
      color: $coming-soon-item-text-svg;
    }
  }

  &__presale {
    min-height: 24px;
    background-color: $presale-item-bg;
    @include svgSize(16px, 16px);
    @include svgFill($presale-item-text-svg);

    &__active {
      height: 24px;
      max-width: 48px;
    }

    &__title {
      color: $presale-item-text-svg;
      @extend %subheading-sb;
    }
  }

  &__days {
    background-color: $info-750;
    @include svgSize(16px, 16px);
    @include svgFill($info-50);

    &__title {
      text-transform: lowercase;
      color: $info-75;
    }
  }

  &__available {
    background-color: $success-125;
    border: 1px solid $success-25;

    &__title {
      color: $success-25;
    }
  }
}

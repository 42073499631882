@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

$mobile-m: 376px;

.wrapper {
  gap: 16px;
  width: 100%;
  padding: 24px;
  border-radius: 0 0 16px 16px;
  background-color: $secondary-400;
  @extend %flex-end-between;

  @media (max-width: $wide) {
    flex-direction: column;
    align-items: center;
    padding: 16px;
  }

  &__links {
    width: 32px;
    height: 32px;
    padding: 5px;
    border-radius: 50%;
    border: 1px solid $primary-300;
    transition: 0.2s all ease-in-out;
    @extend %flex-center;

    &__footer {
      gap: 12px;
      @extend %flex-align;

      &__text {
        color: $secondary-1500;
        white-space: nowrap;
        @extend %body1-r;
      }

      &__dot {
        color: $secondary-1100;
      }

      &__href {
        color: $primary-10;
        white-space: nowrap;
        @extend %button-text;

        @media (max-width: $tablet) {
          font-size: 12px;
          font-weight: 600;
          line-height: 18px;
        }
      }
    }

    @media (max-width: $mobile-m) {
      width: 24px;
      height: 24px;
    }

    &:hover {
      background-color: $secondary-100;
    }

    @include svgFill($primary-10);
  }

  &__middle {
    gap: 24px;
    @extend %flex-column-center;

    @media (max-width: $desktop) {
      gap: 16px;
    }

    &__top {
      gap: 16px;
      @include svgSize(24px, 24px);
      @extend %flex-center;

      @media (max-width: $desktop) {
        flex-direction: column;
        gap: 8px;
      }

      &__text {
        color: $primary-1900;
        text-align: center;
        @extend %secondary-heading;
      }
    }

    &__bottom {
      gap: 16px;
      @extend %flex-align;

      @media (max-width: $desktop) {
        flex-direction: column;
        width: 100%;
        max-width: 300px;
      }

      &__button {
        max-width: 144px;

        @media (max-width: $desktop) {
          max-width: unset;
        }
      }
    }
  }
}

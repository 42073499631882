@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

.wrapper {
  gap: 24px;
  @extend %flex-column;

  @media (max-width: $tablet-m) {
    gap: 16px;
  }

  &__column {
    gap: 16px;
    @extend %flex-column;
  }

  &__input {
    padding: 0 32px;

    @media (max-width: $tablet-m) {
      padding: 0 16px;
    }
  }

  &__container {
    gap: 16px;
    background-color: $background-80;
    padding: 24px;
    @extend %flex-column;

    @media (max-height: $tablet-m) {
      height: auto;
      padding: 16px;
    }
  }

  &__link {
    cursor: pointer;
    color: $primary-10;
    white-space: nowrap;
    text-align: center;
    @extend %button-text;

    @media (max-width: $tablet-m) {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

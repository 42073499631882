@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

$wide: 1540px;

.wrapper {
  min-width: 484px;
  background-color: $secondary-600;
  border-radius: 16px;
  padding: 0 16px 0 24px;
  overflow: hidden;
  display: flex;
  gap: 22px;

  @media (max-width: $tablet) {
    min-width: unset;
    padding: 0 16px;
  }

  &__banner {
    display: flex;
    max-width: 122px;
    margin-top: 5px;
    overflow: hidden;
    border-radius: 8px 8px 0 0;
  }

  &__info {
    padding: 24px 0;

    &_title {
      font-size: 20px;
      line-height: 28px;
      color: #e6f4ff;
    }

    &_chat {
      margin: 16px 0 24px;
      display: flex;
      flex-wrap: wrap;
      gap: 8px 12px;

      &_item {
        cursor: pointer;
        gap: 4px;
        display: flex;
        align-items: center;

        p {
          color: #9fa8b3;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    &_control {
      display: flex;
      justify-content: flex-end;

      @media (max-width: 1200) {
        justify-content: flex-start;
      }
    }
  }

  &_button {
    max-width: max-content;
  }
}

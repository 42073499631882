@import 'styles/vars';
@import 'styles/colors';
@import 'styles/typography';

.wrapper {
  gap: 32px;
  padding: 32px;
  @extend %flex-column;

  &__heading {
    color: $white;
    @extend %heading-4;
  }

  &__table {
    gap: 16px;
    @extend %flex-column;

    &__container {
      gap: 32px;
      display: flex;
    }

    &__fluctuation {
      gap: 12px;
      flex: 1 1 0;
      @extend %flex-column;
    }

    &__divider {
      width: 1px;
      height: 82px;
      background: radial-gradient(circle, $secondary-1100 0%, rgba(39, 60, 83, 0) 100%);
    }
  }

  &__market {
    position: relative;
    @extend %flex-justify-between;

    &__name {
      @extend %body2-sb;
      color: $secondary-1600;

      @media (max-width: $tablet) {
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
      }
    }

    &__value {
      gap: 8px;
      display: flex;

      &__increment {
        stroke: $success-75;
        transform: rotate(0deg);

        &_percent {
          color: $success-75;
        }
      }
      &__decrement {
        stroke: $error-75;
        transform: rotate(70deg);

        &_percent {
          color: $error-75;
        }
      }
    }

    &__price {
      @extend %body2-r;
      text-align: right;
      color: $primary-1900;

      @media (max-width: $tablet) {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
      }
    }

    &__days {
      @extend %body2-sb;
      color: $secondary-1600;
      text-wrap: nowrap;
      width: 100%;
    }
  }

  &__divider {
    width: 100%;
    height: 1px;
    background: radial-gradient(circle, $background-80 0%, rgba(20, 40, 55, 0) 100%);
  }
}

@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

.pie {
  position: relative;
  @extend %flex-center;

  &__chart {
    z-index: 1;
  }

  &__active {
    gap: 8px;
    width: 236px;
    height: 236px;
    border-radius: 50%;
    margin: 0 auto;
    position: absolute;
    background-color: $secondary-200;
    @extend %flex-column-center;

    @media (max-width: $mobile) {
      gap: 4px;
    }

    &__image {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }

    &_round_color {
      width: 16px;
      min-height: 16px;
      border-radius: 50%;
      margin: 0 auto;
    }

    &_title {
      text-align: center;
      color: $primary-1900;
      margin: 0 auto;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 160px;
      @extend %subheading-r;
    }

    &_value {
      text-align: center;
      color: $primary-1600;
      @extend %heading-4;
    }
  }
}

.container {
  gap: 16px;
  width: 100%;
  border-radius: 16px;
  position: relative;
  @extend %flex-column;
}

.wrapper {
  gap: 10px;
  @extend %flex-column;

  &__item {
    max-height: 36px;
    transition: all 0.3s;
    overflow: hidden;

    &__active {
      max-height: 200px;
    }

    &_head {
      padding: 8px;
      cursor: pointer;
      padding-left: 12px;
      border-radius: 8px;
      transition: all 0.3s;
      border: 1px solid transparent;
      background-color: $primary-800;
      @extend %flex-justify-between;
      @include svgFill($secondary-1300, 20px);

      &:hover {
        background-color: $background-70;
      }

      &__icon {
        transition: all 0.3s;
        transform: rotate(0);

        &__active {
          transform: rotate(90deg);
        }
      }

      &__name {
        gap: 7px;
        @extend %flex-align;

        &_badge {
          width: 18px;
          height: 18px;
          border-style: solid;
          border-radius: 50%;
        }

        &_text {
          @extend %body2-r;
          color: $secondary-1500;

          &_active {
            color: $primary-1900;
          }
        }

        &_value {
          display: flex;
          @extend %body2-r;
          align-items: center;
          color: $primary-1900;
        }
      }

      &_active {
        border-color: $background-70;
        border-radius: 8px 8px 0 0;
        background-color: $background-80;

        &:hover {
          background-color: transparent;
        }
      }
    }

    &_list {
      background-color: $background-70;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      @extend %flex-column;

      &_item {
        gap: 8px;
        padding: 5px 12px;
        @extend %flex-justify-between;

        &__divider {
          width: 100%;
          max-height: 1px;
          min-height: 1px;
          background: radial-gradient(50.37% 146930699.63% at 50.37% 0%, #142837 0%, rgba(20, 40, 55, 0) 100%);
        }

        &_title {
          color: $secondary-1500;
          @extend %micro-r;
        }

        &_value {
          color: $primary-1900;
          @extend %micro-sb;
        }
      }
    }
  }
}

.wrapper__item_head:hover .wrapper__item_head__name_text {
  color: $primary-1900;
}

@import 'styles/vars';

.wrapper {
  padding-left: 104px;
  width: 100%;
  height: 100%;

  @media (max-width: $desktop) {
    padding-left: 0;
  }
}

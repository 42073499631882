@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

$wide: 1540px;

.wrapper {
  min-width: 484px;
  height: 276px;
  background-color: $secondary-600;
  border-radius: 16px;
  padding: 0 24px;
  overflow: hidden;
  @extend %flex-center;

  @media (max-width: $tablet) {
    min-width: unset;
    padding: 0 16px;
  }

  &__left {
    padding: 32px 0;
    height: 100%;
    @include svgFill($primary-700, 40px);

    @media (max-width: $tablet) {
      padding: 16px 0;
    }

    @media (max-width: $mobile) {
      @include svgFill($primary-700, 32px);
    }
  }

  &__right {
    padding: 32px 0;
    height: 100%;
    @extend %flex-align-end;
    @include svgFill($primary-700, 40px);

    @media (max-width: $tablet) {
      padding: 16px 0;
    }

    @media (max-width: $mobile) {
      @include svgFill($primary-700, 32px);
    }

    > svg {
      transform: rotate(180deg);
    }
  }

  &__slider {
    gap: 24px;
    width: 100%;
    height: 75%;
    position: relative;
    transition: 0.6s all;
    @extend %flex-column-center;

    @media (max-width: $wide) {
      height: 100%;
    }

    @media (max-width: $tablet) {
      height: 75%;
    }

    @media (max-width: $mobile) {
      height: 100%;
    }

    &:hover .wrapper__slider__text {
      animation-play-state: paused;
    }

    &__text {
      position: absolute;
      text-align: center;
      left: 50%;
      width: 100%;
      opacity: 0;
      transform-origin: left;
      transform: translate(-50%, -50%);
      animation: slide 10s linear infinite;
      color: $secondary-1200;
      @extend %body1-r;

      @media (max-width: $wide) {
        white-space: nowrap;
      }

      @media (max-width: $tablet) {
        white-space: normal;
        width: 75%;
      }
    }
  }
}

@keyframes slide {
  0% {
    top: 0;
    opacity: 0;
    scale: 1;
    transform: translate(-50%, 0%);
  }
  5%,
  25% {
    top: 0;
    scale: 1;
    color: $secondary-1200;
    transform: translate(-50%, 0%);
  }
  40%,
  60% {
    opacity: 1;
    top: 50%;
    scale: 1.2;
    color: $success-75;
    pointer-events: auto;
    text-shadow: 0 0 12px #47cb928c;
    transform: translate(-50%, -50%);
  }
  75%,
  95% {
    top: 100%;
    scale: 1;
    color: $secondary-1200;
    transform: translate(-50%, -100%);
  }
  100% {
    top: 100%;
    opacity: 0;
    scale: 1;
    transform: translate(-50%, -100%);
  }
}

@import 'styles/vars';
@import 'styles/colors';
@import 'styles/typography';

.wrapper {
  top: 50%;
  left: 0;
  z-index: 4;
  width: 264px;
  position: absolute;
  transform: translateY(-50%);
  border-radius: 16px;
  transition: width 1s, padding 1s;
  padding: 32px 0 24px 0;
  height: 100%;
  user-select: none;
  max-height: 788px;
  background-color: $secondary-500;
  @extend %flex-column-between;
}

.wrapper__closed {
  width: 80px;
  padding: 32px 0 24px;
}

.is_open {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: $opened-sidebar-bg;
}

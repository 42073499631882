@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

.wrapper {
  width: 100%;
  position: relative;

  &__label {
    color: $secondary-1500;
    box-sizing: border-box;
    margin-bottom: 4px;
    @extend %body1-r;
  }

  &__container {
    width: 100%;
    position: relative;
    transition: 0.6s all;
    border: 1px solid transparent;
    background: $background-80;
    border-radius: 8px;

    &__field_error {
      border-color: $error !important;
    }

    &__error {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 8px;
    }

    &__field {
      width: 100% !important;
      position: relative;

      fieldset {
        border: unset !important;
      }

      input {
        width: 100%;
        height: 48px;
        outline: none;
        padding: 0 12px 0 0;
        font-size: 16px;
        @extend %body2-lt;
        border-radius: 8px;
        transition: 0.3s all;
        box-sizing: border-box;
        background: $background-80;
        border: 1px solid transparent;
        color: $primary-1900 !important;
      }

      &__error fieldset {
        border: 1px solid $error !important;
      }

      &__error input {
        padding-right: 48px;
      }
    }
  }
}

.customMenuContainer {
  width: 444px;
  height: 400px;
  background-color: $primary-900;
  @include scrollbar(5px);
}

.customMenuItem {
  background-color: $primary-900;
  color: $secondary-1500;

  p {
    color: $secondary-1500;
  }
}

@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

.wrapper {
  width: 100%;
  border: 1px solid $secondary-1100;

  &__content {
    gap: 32px;
    padding-bottom: 32px;
    @extend %flex-column-center;
  }

  &__title {
    margin-bottom: 8px;
  }

  &__button {
    max-width: max-content;
  }

  &__label {
    width: 100%;
    @extend %body2-r;
    text-align: center;
    color: $secondary-1600;

    @media (max-width: $tablet-m) {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  &__success__icon {
    @include svgFill($success, 40px);
  }

  &__error__icon {
    @include svgFill($error, 40px);
  }
}

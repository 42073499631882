@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

$wide-m: 1366px;

.wrapper {
  width: 100%;
  max-width: 1252px;
  padding: 24px 32px 20px;
  border-radius: 16px;
  background-color: $secondary-600;

  @media (max-width: $tablet) {
    padding: 16px;
  }

  &__header {
    gap: 16px;
    width: 100%;
    @extend %flex-justify-between;

    &__text {
      color: $primary-1900;
      @extend %subheading-m;

      @media (max-width: 340px) {
        font-size: 18px;
      }
    }

    &__container {
      gap: 16px;
      @extend %flex-align;
    }

    &__button {
      max-width: max-content;
    }
  }

  &__container {
    gap: 16px;
    display: flex;
    overflow-x: auto;
    margin-top: 16px;
    max-width: 1188px;
    padding-bottom: 8px;
    scroll-behavior: smooth;
    width: calc(100vw - 726px);
    clip-path: inset(0 0 8px 0);
    @include scrollbarHorizontal(0px);

    @media (max-width: $wide-m) {
      width: 100%;
    }

    &__card {
      min-width: 274px;
    }
  }
}

.hidden {
  min-width: 20px;
  @extend %flex-center;
}

@import 'styles/vars';
@import 'styles/mixins';
@import 'styles/colors';
@import 'styles/typography';

.wrapper {
  width: 100%;
  position: relative;

  &__label {
    margin-bottom: 4px;
    @extend %body1-r;
    color: $secondary-1500;
  }

  &__select {
    height: 48px;
    width: 100%;
    background: $background-80;
    border: 1px solid transparent;
    border-radius: 8px;
    transition: 0.3s all;

    &__container {
      height: 48px;
      padding-bottom: 15px;
      color: $secondary-1400 !important;
      @include svgFill($secondary-1400 !important, 20px);

      ul {
        background: $background-80;

        li {
          background-color: $background-90;
          color: $secondary-1400 !important;
        }
      }

      &__menu {
        background: transparent;
      }
    }
  }
}

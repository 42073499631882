@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

.wrapper {
  gap: 8px;
  position: relative;
  @extend %flex-column;

  &__icon {
    gap: 8px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    border-radius: 8px;
    position: relative;
    transition: all 0.3s;
    background-color: $primary-800;
    @extend %flex-column-center;

    &:hover {
      background-color: $primary-700;
    }

    &:hover &__item {
      background-color: $primary-1900;
    }

    &__close {
      margin-top: 3px;
      @include svgFill($primary-1600, 20px);

      svg {
        transition: all 0.5s, opacity 1s;
        opacity: 0;
        transform: rotate(0);
      }

      &__active svg {
        opacity: 1;
        transform: rotate(360deg);
      }
    }

    &__item {
      width: 4px;
      position: absolute;
      height: 4px;
      border-radius: 50%;
      background-color: $primary-1600;
      transition: top 0.5s, scale 0.5s, opacity 1s, background-color 0.6s;
      opacity: 1;
    }

    &__dot1 {
      top: 6px;

      &__passive {
        opacity: 0;
        top: 14px;
      }
    }

    &__dot2__passive {
      opacity: 0;
      scale: 1.35;
    }

    &__dot3 {
      top: 22px;

      &__passive {
        opacity: 0;
        top: 14px;
      }
    }
  }

  &__dropdown {
    width: 264px;
    position: absolute;
    top: 40px;
    left: 0;
    border-radius: 8px;
    background-color: $secondary-300;
    max-height: 0;
    opacity: 0;
    right: 0;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
    gap: 8px;
    overflow: hidden;
    transition: all 0.7s;
    @extend %flex-column;

    &__links {
      @include blueBorderedSocialLinks();

      &__container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        padding: 12px 24px;
        grid-row-gap: 12px;
        grid-column-gap: 28px;
        border-bottom: 1px solid $secondary-1100;
      }
    }

    &__head {
      border-bottom: 1px solid $secondary-1100;
      padding-bottom: 8px;
    }

    &__footer {
      @extend %flex-column-center;
      gap: 8px;
      text-align: center;
      padding: 12px 24px;

      &__link {
        cursor: pointer;
        color: $primary-10;
        @extend %button-text;
        text-decoration: underline;
      }

      &__version {
        @extend %micro-sb;
        color: $secondary-1700;
      }

      &__rights {
        @extend %micro-lt;
        color: $primary-1700;
      }
    }

    &__item {
      padding: 12px 12px 12px 24px;
      @extend %body1-r;
      color: $secondary-1600;
      cursor: pointer;
      transition: all 0.7s;
      width: 100%;

      &__disabled {
        padding: 12px 12px 12px 24px;
        color: $secondary-1200;
        @extend %flex-justify-between-center;
      }

      &:hover {
        background-color: $background-70;
        color: $white;
      }
    }

    &__dropped {
      max-height: 450px;
      opacity: 1;
      padding: 8px 0;
      overflow: hidden;
    }
  }
}

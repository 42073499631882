@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

$wide-l: 1440px;

.wrapper {
  gap: 16px;
  z-index: 4;
  width: 100%;
  height: 72px;
  max-width: 1864px;
  padding: 12px 16px;
  border-radius: 8px;
  position: relative;
  background-color: $secondary-500;
  @extend %flex-justify-between;

  @media (max-width: $desktop) {
    border-radius: 0;
    position: fixed;
    top: 0;
    z-index: 3;
    left: 0;
    max-height: 64px;
  }

  &_right {
    gap: 8px;
    display: flex;
  }

  &__row {
    gap: 16px;
    @extend %flex-align;
  }

  &__mobile_buttons {
    gap: 8px;
    @extend %flex-align;
  }

  &__center {
    gap: 8px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    @extend %flex-align;
    @include svgFill($primary-1800);

    &__icon {
      width: 40px;
      height: 40px;
    }

    &__text {
      width: 213px;
      height: 30px;
    }
  }

  &__mobile_nav_button {
    border: unset;
    width: fit-content;
    background-color: $secondary-30;
    @include svgSize(24px, 24px);
    @include svgFill($white);

    &:focus,
    &:hover {
      border: unset;
      background-color: $secondary-300;
      @include svgFill($white);
    }
  }
}

@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

.wrapper {
  max-width: 416px;
  background-color: $secondary-400;
  border: 1px solid $secondary-100;

  &__content {
    gap: 16px;
    padding-bottom: 32px;
    @extend %flex-column;
  }

  &__title_container {
    padding: 32px 32px 16px;
  }

  &__date {
    width: 100%;
    padding: 12px 16px;
    border-radius: 8px;
    color: $secondary-1700;
    background-color: $secondary-200;
    @extend %subheading-r;
    @extend %flex-center;

    &__container {
      gap: 8px;
      @extend %flex-column;
    }
  }

  &__divider {
    width: 100%;
    min-height: 1px;
    max-height: 1px;
    background-color: $secondary-30;
  }

  &__container {
    width: 100%;
    @extend %flex-justify-between-center;

    &__time {
      gap: 8px;
      color: $secondary-1500;
      @extend %body1-lt;
      @extend %flex-align;
      @include svgFill($secondary-1500, 20px);
    }

    &__percent {
      gap: 16px;
      color: $primary-1900;
      @extend %flex-align;
      @extend %heading-4;
    }
  }
}

@import 'styles/vars';
@import 'styles/mixins';
@import 'styles/colors';

.wrapper {
  gap: 24px;
  width: 100%;
  padding: 28px;
  height: 100vh;
  @extend %flex-column-center;

  @media (max-width: $desktop) {
    gap: 0;
    padding: 0;
    gap: 16px;
    height: auto;
    justify-content: flex-start;
  }

  &__content {
    width: 100%;
    max-width: 1864px;
    max-height: 788px;
    position: relative;
    z-index: 2;
    height: calc(100vh - 124px);
    @extend %flex-center;

    @media (max-width: $desktop) {
      max-height: unset;
    }
  }
}

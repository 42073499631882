@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

.wrapper {
  gap: 4px;
  @extend %flex-align;

  &__input {
    width: 48px;
    height: 48px;
    @include inputStyles();
    padding: 16px 18px;
    @extend %body1-r;
  }

  &__container {
    position: relative;
    @include svgFill($secondary-1400, 16px);

    &__icon {
      position: absolute;
      height: 0;
      cursor: text;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

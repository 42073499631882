@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

.wrapper {
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 30px;
  height: calc(100vh - 356px);
  @extend %flex-column;
  @include scrollbar(4px, 4px);

  &__title {
    color: $sidebar-title;
    padding: 8px 12px 8px 24px;
    @extend %micro-r;
    @extend %flex-align;
  }

  &__item {
    gap: 8px;
    width: 100%;
    height: 48px;
    display: flex;
    cursor: pointer;
    overflow: hidden;
    transition: all 1s;
    padding: 12px 12px 12px 16px;
    @extend %flex-justify-between;

    &:hover {
      transition: all 0.5s;
      background-color: $secondary-100;

      .wrapper__item__box {
        @include svgFill($sidebar-text-svg-hover);
      }

      .wrapper__item__text {
        color: $sidebar-text-svg-hover;
      }

      .wrapper__item__box__soon {
        animation: scale 0.3s linear;

        & > svg {
          perspective: 1000px;
          backface-visibility: hidden;
          transform: translate3d(0, 0, 0);
          animation: shake 0.82s 0.3s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        }
      }
    }

    &__text {
      width: 176px;
      overflow: hidden;
      transition: all 1s;
      white-space: nowrap;
      color: $sidebar-text-svg-default;
      @extend %body1-r;
    }

    &__box {
      gap: 8px;
      transition: 1s all;
      @extend %flex-align;
      @include svgFill($sidebar-text-svg-default, 20px);

      &__soon {
        width: 82px;
      }

      &__presale {
        width: 100px;
      }
    }
  }

  &__active_item {
    background-color: $primary-900;

    &__text {
      color: $sidebar-text-svg-active;
    }

    .wrapper__item__box {
      @include svgFill($sidebar-text-svg-active);
    }

    &:hover {
      background-color: $primary-900;

      .wrapper__item__box {
        @include svgFill($sidebar-text-svg-active);
      }

      .wrapper__active_item__text {
        color: $sidebar-text-svg-active;
      }
    }
  }

  &__closed {
    &__title {
      width: 100%;
      height: 34px;
      background-color: $sidebar-title-bg;
    }

    &__item {
      gap: 0;
      width: 100%;
      @extend %flex-center;

      &__badge {
        width: 24px;
      }

      &__text {
        width: 0;
        opacity: 0;
      }
    }
  }
}

.without_soon {
  padding-left: 31px;
  justify-content: flex-start;
}

@keyframes scale {
  0% {
    scale: 1;
  }
  50% {
    scale: 1.1;
  }
  100% {
    scale: 1;
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(1px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-1.5px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(1.5px, 0, 0);
  }
}

@import 'styles/vars';
@import 'styles/colors';

.radio_wrapper {
  height: 20px;
  color: $white;
  @extend %flex-align;

  input[type='radio'] {
    cursor: pointer;
    accent-color: $primary-10;
  }

  label {
    cursor: pointer;
  }
}

.disabled {
  opacity: 0.7;
  color: $secondary-1500;
}

.right {
  @extend %flex-align;
  flex-direction: row-reverse;

  & input {
    margin: 0 8px;
  }
}

.top {
  @extend %flex-column-center;
}

.bottom {
  @extend %flex-center;
  flex-direction: column-reverse;
}

@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

.wrapper {
  gap: 8px;
  @extend %flex-column;

  &__divider {
    background-color: $secondary-100;
  }

  &__title {
    gap: 8px;
    white-space: nowrap;
    color: $secondary-1800;
    @extend %subheading-r;
    @include svgFill($secondary-1800);
  }

  &__container {
    width: 100%;
    @extend %flex-justify-between-center;

    &__time {
      gap: 8px;
      color: $secondary-1500;
      @extend %body1-lt;
      @extend %flex-align;
      @include svgFill($secondary-1500, 20px);
    }

    &__percent {
      gap: 16px;
      color: $primary-1900;
      @extend %flex-align;
      @extend %heading-4;
    }
  }
}

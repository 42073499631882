@import 'styles/colors.scss';
@import 'styles/mixins.scss';
@import 'styles/typography.scss';
@import 'styles/vars.scss';

.wrapper {
  min-width: 64px;
  height: 32px;
  opacity: 0.5;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  background-color: $secondary-600;
  @include svgFill($mode-switch-svg);
  @extend %flex-justify-between;

  &__item {
    width: 32px;
    height: 32px;
    @extend %flex-center;

    @include svgSize(20px) {
      z-index: 1;
    }

    &:hover {
      @include svgFill($mode-switch-svg-hover);
    }
  }

  &__switcher {
    height: 100%;
    background-color: $secondary-100;
    position: absolute;
    border-radius: 6px;
    z-index: 0;
    left: 0;
    top: 0;
    transition: all 0.3s ease;
  }

  &__switcher_active {
    transform: translate(100%);
  }

  &__light_active {
    @include svgFill($mode-switch-svg-hover);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 6px 2px transparent, 0 0 0 0 transparent, 0 0 0 0 transparent;
  }

  50% {
    box-shadow: 0 0 6px 2px $mode-switch-shadow, 0 0 12px 4px transparent, 0 0 12px 5px $mode-switch-shadow;
  }

  100% {
    box-shadow: 0 0 6px 2px transparent, 0 0 0 9px transparent, 0 0 0 9px transparent;
  }
}

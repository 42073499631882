@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

.wrapper {
  gap: 12px;
  width: 100%;
  cursor: pointer;
  max-width: 274px;
  position: relative;
  border-radius: 16px;
  padding-bottom: 8px;
  transition: 0.6s all;
  border: 1px solid transparent;
  background-color: $secondary-500;
  @extend %flex-column;

  &:hover {
    border-color: $secondary-100;
    background-color: $secondary-200;

    .wrapper__container__hidden {
      opacity: 1;
    }

    .wrapper__image__container {
      padding: 0;
    }

    .wrapper__text {
      color: $primary-1900;
    }
  }

  &__large {
    max-width: 424px;
    padding-bottom: 24px;

    .wrapper__image {
      height: 210px;
    }
    .wrapper__image__container {
      padding: 24px 24px 0;
      height: 210px;
    }

    .wrapper__text {
      padding: 0 24px;
      @extend %heading-4;
    }

    .wrapper__container__hidden {
      padding: 0 24px;
    }

    .wrapper__container__play {
      height: 210px;
    }

    .wrapper__container {
      gap: 32px;
    }
  }

  &__modal {
    overflow: unset;
    max-width: 80%;
    padding: 0 0 32px;
    max-height: 90vh;
  }

  &__iframe {
    width: 100%;
    height: 100%;
  }

  &__image {
    border-radius: 8px;
    object-fit: cover;
    width: 100%;
    height: 136px;

    &__container {
      width: 100%;
      height: 136px;
      transition: 0.6s all;
      position: absolute;
      padding: 16px 16px 0;
    }
  }

  &__text {
    padding: 0 16px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    transition: 0.6s all;
    -webkit-box-orient: vertical;
    color: $secondary-1600;
    @extend %body1-r;
  }

  &__stats {
    gap: 16px;
    @extend %flex-align;

    &__text {
      gap: 8px;
      color: $secondary-1600;
      @extend %body2-r;
      @extend %flex-align;
      @include svgFill($secondary-1600);
    }
  }

  &__container {
    z-index: 1;
    gap: 5px;
    @extend %flex-column;

    &__hidden {
      padding: 0 16px;
      opacity: 0;
      transition: 0.6s all;
      background: linear-gradient(180deg, rgba(8, 19, 32, 0.2) 0%, #0c1e33 87.5%);
    }

    &__play {
      height: 136px;
      @extend %flex-center;

      &__icon {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: $primary-200;
        @extend %flex-center;
        @include svgFill($primary-1900);
      }
    }
  }
}
// & > button {
//   width: 70px;
//   height: 46px;
//   background-color: #212121;
//   z-index: 1;
//   opacity: 0.8;
//   border-radius: 14%;
//   transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
//   position: absolute;
//   z-index: 1;

//   &:hover {
//     background-color: red;
//     opacity: 1;
//   }

//   &:before {
//     content: '';
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate3d(-50%, -50%, 0);
//     border-color: transparent transparent transparent #fff;
//     border-style: solid;
//     border-width: 11px 0 11px 19px;
//   }
// }

@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

.wrapper {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 16px;
}

.header {
  gap: 16px;
  padding: 32px;
  background-color: $secondary-400;
  @extend %flex-justify-between-center;

  &__title {
    color: $primary-1900;
    @extend %heading-4;
  }

  &__button {
    max-width: max-content;
  }

  &__container {
    gap: 32px;
    @extend %flex-align;

    &__buy_sell {
      gap: 24px;
      @extend %flex-align;
    }

    &__date {
      gap: 16px;
      @extend %flex-align;

      &__text {
        color: $secondary-1700;
        @extend %subheading-m;
      }
    }
  }
}

.days {
  display: flex;
  color: $secondary-1700;
  background-color: $secondary-200;
  height: 72px;
}

.col {
  flex: 1 1 0;
  text-align: center;
  border: 1px solid $secondary-30;
  @extend %flex-center;
}

.row {
  display: flex;
}

.cell {
  overflow: hidden;
  position: relative;
  height: 112px;
  transition: all 0.6s;
  @extend %flex-start;

  &__text {
    transition: all 0.6s;
    color: $secondary-1500;
    @extend %body1-r;
  }

  &__container {
    height: 100%;
    padding: 16px;
    @extend %flex-column-between;
  }

  &__buy_sell {
    gap: 8px;
    @extend %flex-align;
  }

  &__disabled {
    background: $secondary-300;

    .cell__text {
      color: $secondary-1300;
    }

    .number {
      color: $secondary-1300;
      background-color: $secondary-300;
    }
  }

  &__selected:hover {
    background: $secondary-700;
    border: 1px solid $primary-10;
    border-radius: 8px;
    cursor: pointer;

    .cell__text {
      color: $primary-1900;
    }

    .number {
      color: $primary-1900;
      background-color: $secondary-1100;
    }
  }
}

.number {
  transition: all 0.6s;
  background-color: $secondary-100;
  position: absolute;
  color: $secondary-1500;
  right: 0;
  bottom: 0;
  border-radius: 12px 0 0 0;
  @include minMaxSize(32px, 32px);
  @extend %flex-center;
  @extend %body1-r;
}

@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

.modal {
  width: 100%;
  padding: 1px;
  max-width: 938px;
  max-height: 686px;

  &_left {
    height: 70vh;
    overflow: hidden;
    min-width: 248px;
    max-width: 248px;
    padding: 32px 20px;
    position: relative;
    border-radius: 8px 0 0 8px;
    background-color: $secondary-700;
    @extend %flex-column-between;

    &__header {
      gap: 8px;
      z-index: 1;
      @extend %flex-column;

      &__text {
        color: $secondary-1600;
        @extend %micro-lt;
      }
    }

    &__footer {
      color: $secondary-1500;
      z-index: 1;
      @extend %micro-r;

      &__terms {
        padding: 0 2px;
        color: $secondary-1300;
        display: inline-block;
        @extend %micro-r;

        &:hover {
          text-decoration: none;
        }
      }

      &__link {
        padding: 0 2px;
        color: $primary-10;
        display: inline-block;
        @extend %micro-r;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &__logo {
      position: absolute;
      left: -120px;
      bottom: -120px;
      @include svgFill($secondary-400, 265px);
    }
  }

  &_content {
    width: 100%;
    display: flex;
    padding-right: 0;

    &__wrapper {
      width: 100%;
      height: 100%;
      @extend %flex-column-between;
    }

    &__container {
      gap: 16px;
      padding: 24px 32px;
      @extend %flex-column;

      @media (max-width: $tablet-m) {
        padding: 16px;
      }
    }

    &__scrollable {
      @extend %flex-column;

      &::-webkit-scrollbar-track {
        margin-block: 16px;
      }

      @media (max-width: $desktop) {
        gap: 16px;
        height: calc(100% - 92px);
        max-height: max-content;
        overflow-y: auto;
        scrollbar-gutter: stable;
        @include scrollbar(4px, 4px);
      }

      @media (max-height: $tablet) {
        padding-right: 8px;
        margin-right: 12px;
      }
    }
  }
}

.scan {
  padding: 16px;
  border-radius: 8px;
  background: $background-80;
  border: 1px solid $background-70;

  &__copy {
    width: max-content;

    &__active {
      @include svgFill($success);
    }
  }

  &__head {
    gap: 8px;
    margin-bottom: 15px;
    @extend %flex-align-between;

    &_title {
      color: $primary-1900;
      @extend %subheading-r;
    }

    &__block {
      gap: 8px;
      @extend %flex-align;

      &_code {
        cursor: default;
        @extend %body1-lt;
        color: $secondary-1600;

        &::selection {
          color: $secondary-1600;
          background: transparent;
        }

        @media (max-width: $mobile-l) {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: calc(100vw - 250px);
        }
      }
    }
  }

  &__qr {
    @extend %flex-justify-center;

    & > canvas {
      width: 160px !important;
      height: 160px !important;
    }
  }
}

.verify {
  border-radius: 8px;
  padding: 16px 24px 24px;
  background: $primary-900;

  @media (max-width: $tablet-m) {
    padding: 16px;
  }

  &__head {
    margin-bottom: 16px;
    @extend %flex-justify-between-center;

    @media (max-width: $tablet-m) {
      gap: 4px;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 8px;
    }

    &__title {
      color: $primary-1900;
      @extend %subheading-r;
    }

    &_right {
      gap: 4px;
      display: flex;

      &_text {
        color: $secondary-1500;
        @extend %body1-r;
      }

      &_help {
        color: $primary-10;
        @extend %button-text;
      }
    }
  }

  &__inputs {
    gap: 4px;
    @extend %flex-center;

    &_item {
      position: relative;
      text-align: center;
      height: 48px;
      width: 48px;

      @media (max-width: $mobile-m) {
        height: 40px;
        width: 40px;
      }

      &_container {
        height: 48px;
        width: 48px !important;
        border-radius: 8px;
        background: $background-80;
        outline: none;
        text-align: center;
        border: none;
        z-index: 1;
        color: $primary-1900;
        @extend %subheading-r;

        @media (max-width: $mobile-m) {
          height: 40px;
          width: 40px !important;
        }

        &_active {
          background-color: $success-75;
        }
      }

      &_inp {
        height: 48px;
        width: 48px;
        border-radius: 8px;
        outline: none;
        text-align: center;
        border: none;
        z-index: 3;
        position: absolute;
        color: $secondary-1400;
        background-color: transparent;
        @extend %subheading-r;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        display: block;

        @media (max-width: $mobile-m) {
          height: 40px;
          width: 40px;
        }
      }

      &_svg {
        position: absolute;
        z-index: 2;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;

        path {
          fill: $secondary-1400;
        }
      }
    }
  }
}

.submitting {
  padding: 24px;
  display: flex;
  justify-content: flex-end;
  background: $background-80;
  gap: 16px;

  @media (max-width: $tablet-m) {
    padding: 16px;
  }

  &_btn {
    width: 100%;
    padding: 12px 16px;
  }
}

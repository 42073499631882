@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

$phone: '../../../../assets/images/blog/phone.png';
$phoneMobile: '../../../../assets/images/blog/phoneMob.png';
$wide-m: 1366px;

.wrapper {
  gap: 16px;
  width: 484px;
  border-radius: 16px;
  overflow: hidden;
  transition: all 0.6s;
  background-color: $secondary-500;
  padding: 24px 24px 20px;

  @media (max-width: $wide-m) {
    width: auto;
    flex: 1;
  }

  &__title {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    color: $primary-1900;
  }

  &__button {
    max-width: max-content;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    &_controller {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }

  &__cards {
    overflow: scroll;

    &_content {
      gap: 18px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      flex-wrap: wrap;
      transition: all 0.5s ease-in-out;
      padding: 2px;
    }

    &_item {
      min-width: 200px;
      gap: 16px;
      padding: 8px;
      display: flex;
      align-items: center;
      background: #03080d;
      border-radius: 8px;
      outline: 0;
      transition: all 0.2s ease-in-out;

      p {
        color: #b7bec6;
        font-weight: 500;
        line-height: 24px;
      }

      &:hover {
        cursor: pointer;
        outline: 2px solid $primary-500;

        p {
          color: $primary-500;
        }
      }
    }
  }
}

@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

$phone: '../../../../assets/images/blog/phone.png';
$phoneMobile: '../../../../assets/images/blog/phoneMob.png';

.wrapper {
  gap: 16px;
  width: 484px;
  border-radius: 16px;
  overflow: hidden;
  transition: all 0.6s;
  background-color: $secondary-500;
  border: 1px solid transparent;
  @extend %flex-justify-between;

  @media (max-width: $mobile) {
    flex-direction: column;
    background-image: url($phoneMobile);
    background-repeat: no-repeat;
    background-size: contain;
  }

  &:hover {
    gap: 32px;
    border-color: $primary-1400;

    @media (max-width: $tablet-l) {
      gap: 16px;
    }

    .wrapper__left {
      margin-left: 0;
      background-size: 110%;

      @media (max-width: $mobile) {
        margin-left: 0;
        background-size: 100%;
      }
    }

    .wrapper__left__tooltip {
      margin-left: 16px;
    }
  }

  &__left {
    transition: all 0.6s;
    min-width: 138px;
    height: 100%;
    margin-left: 16px;
    padding-bottom: 24px;
    background-image: url($phone);
    background-repeat: no-repeat;
    background-size: 100%;
    justify-content: flex-end;
    background-position: 100% -10%;
    @extend %flex-column;

    @media (max-width: $tablet-l) {
      background-position: top;
      height: auto;
      padding-bottom: 0;
      margin-left: 0;
    }

    @media (max-width: $mobile) {
      height: 224px;
      background-image: unset;
    }
  }

  &__right {
    width: 100%;
    padding: 24px 16px 24px 0;

    @media (max-width: $tablet-l) {
      padding: 16px;
    }

    &__container {
      gap: 8px;
      margin-top: 12px;
      flex-wrap: wrap;
      @extend %flex-align;
    }

    &__title {
      color: $primary-1900;
      @extend %subheading-m;
    }

    &__links {
      gap: 20px;
      width: 100%;
      margin-top: 32px;
      justify-content: flex-end;
      @extend %flex-align;

      > a {
        width: 100%;
      }

      @media (max-width: $mobile) {
        margin-top: 16px;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
      }

      &__button {
        max-width: max-content;

        @media (max-width: $mobile) {
          max-width: unset;
        }
      }
    }
  }

  &__item {
    gap: 4px;
    margin-left: 4px;
    @extend %flex-align;

    &__icon {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: $success-150;
      @extend %flex-center;
      @include svgFill($success-50, 16px);
    }

    &__text {
      color: $secondary-1600;
      @extend %body2-r;
    }
  }
}

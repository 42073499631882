@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

$bg: '../../../../assets/images/blog/introductionBg.png';

.wrapper {
  gap: 24px;
  max-width: 1252px;
  width: 100%;
  padding: 24px 32px;
  border-radius: 16px;
  background-image: url($bg);
  background-repeat: no-repeat;
  background-size: cover;
  @extend %flex-align;

  @media (max-width: $tablet) {
    flex-direction: column;
    padding: 16px;
  }

  &__left {
    height: 100%;
    @extend %flex-center;

    &__container {
      width: 216px;
      height: 216px;
      border-radius: 50%;
      background-size: 400% 400%;
      animation: gradient 3s infinite linear;
      box-shadow: 0px 0px 80px 0px #0086e14f;
      background: linear-gradient(180deg, #00c6ff 0%, #0072ff 100%);
      @extend %flex-center;

      &__inner {
        width: 200px;
        height: 200px;
        object-fit: contain;
        border-radius: 50%;
      }
    }
  }

  &__right {
    gap: 24px;
    width: 100%;
    height: 100%;
    justify-content: flex-end;
    @extend %flex-column;

    &__introduce {
      &__label {
        color: #6f7d8c;
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
      }

      &__inner {
        white-space: nowrap;
        overflow: hidden;
        color: $primary-1900;
        animation: fadeIn 1s 1s 1 forwards ease;
        opacity: 0;
        @extend %heading-1;

        @media (max-width: $mobile) {
          text-align: center;
        }

        @media (max-width: 350px) {
          font-weight: 700;
          font-size: 28px;
          line-height: 32px;
        }
      }

      &__name {
        animation: fadeIn 1s 1s 1 forwards ease;
        background: -webkit-linear-gradient(180deg, #00c6ff 0%, #0072ff 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        opacity: 0;
      }

      &__tell {
        opacity: 0;
        animation: fadeIn 1s 2s 1 forwards ease;
      }

      &__true {
        opacity: 0;
        color: $success-75;
        animation: fadeIn 1s 2s 1 forwards ease;
        text-shadow: 0 0 12px #47cb928c;

        @media (max-width: $mobile) {
          animation-delay: 3s;
        }
      }
    }

    &__container {
      gap: 16px;
      @extend %flex-justify-between;

      @media (max-width: $tablet-l) {
        justify-content: flex-start;
        flex-direction: column;
      }
    }

    &__social {
      gap: 16px;
      @extend %flex-align;

      @media (max-width: $mobile) {
        flex-direction: column;
      }

      > a {
        width: 100%;
      }
    }
  }

  &__button {
    max-width: max-content;

    @media (max-width: $tablet-l) {
      max-width: unset;
    }
  }

  &__media {
    @include blueBorderedSocialLinks();
    @include svgSize(24px, 24px);
    width: 48px;
    height: 48px;

    &__container {
      gap: 16px;

      @media (max-width: $tablet-l) {
        justify-content: center;
      }
    }
  }
}

@keyframes gradient {
  0% {
    background-position: 80% 0%;
  }
  50% {
    background-position: 20% 100%;
  }
  100% {
    background-position: 80% 0%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

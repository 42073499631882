@import 'styles/vars';
@import 'styles/mixins';
@import 'styles/colors';
@import 'styles/typography';

.wrapper {
  margin: 0 auto;
  border-radius: 50%;
  gap: 8px;
  position: relative;
  @extend %flex-column-between-center;

  &__bg {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    @extend %flex-center;

    &::before {
      content: '';
      width: 150%;
      height: 150%;
      position: absolute;
      overflow: hidden;
      animation: rotate 8s linear infinite;
      background-image: conic-gradient(rgba(102, 191, 252, 0.9) 20deg, transparent 340deg);
      @extend %flex-center;
    }

    &::after {
      content: '';
      width: 45px;
      height: 45px;
      position: absolute;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $primary-800;
      overflow: hidden;
      box-shadow: inset 4px -4px 16px rgba(0, 0, 0, 0.8), inset -4px 4px 16px rgba(0, 149, 250, 0.25);
      @extend %flex-center;
    }
  }

  &__title {
    color: transparent;
    z-index: 2;
    margin: 0 auto;
    -webkit-text-stroke: 1px $primary-10;
    @extend %flex-center;
    @extend %heading-1;
  }

  &__icon {
    position: absolute;
    top: 50%;
    z-index: 0;
    transform: translateY(-50%);
    @include svgFill(#0077c80d, 28px);
  }

  &__presale {
    &__bg {
      &::before {
        background-image: conic-gradient(rgba(8, 140, 83, 0.5) 20deg, transparent 340deg);
      }

      &::after {
        background-color: $success-150;
      }
    }

    &__title {
      -webkit-text-stroke: 1px $success-75;
    }

    &__icon {
      opacity: 0.2;
      @include svgFill($success-150);
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

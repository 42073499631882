@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

.wrapper {
  &__scrollable {
    gap: 24px;
    @extend %flex-column;

    &::-webkit-scrollbar-track {
      margin-block: 16px;
    }

    @media (max-width: $desktop) {
      gap: 16px;
      height: calc(100vh - 256px);
      max-height: max-content;
      overflow-y: auto;
      scrollbar-gutter: stable;
      @include scrollbar(4px, 4px);
      @include scrollbarHorizontal(0);
    }

    @media (max-width: $tablet) {
      height: calc(100vh - 178px);
    }

    @media (max-height: $tablet) {
      padding-right: 8px;
      margin-right: 12px;
    }
  }

  &__step {
    width: 100%;
    height: 6px;
    transition: all 0.6s;
    border-radius: 8px;
    background-color: $background-80;

    &__current {
      background-color: $secondary-1200;
    }

    &__passed {
      background-color: $success;
    }

    &__container {
      width: 100%;
      display: flex;
      flex: 1 1 1;
      gap: 8px;
    }
  }

  &__steps {
    gap: 8px;
    padding: 24px 32px 16px;
    border-bottom: 1px solid $background-70;
    @extend %flex-column;

    @media (max-width: $tablet-m) {
      padding: 16px;
    }

    &__row_wrap {
      gap: 16px;
      @extend %flex-align;

      @media (max-width: $tablet) {
        flex-direction: column;
      }
    }

    &__title {
      border-bottom: none !important;
      padding: 0 0 8px !important;

      @media (max-width: $tablet-m) {
        padding: 0 !important;
      }
    }

    &__count {
      color: $secondary-1600;
      @extend %micro-sb;
    }
  }

  &__footer {
    gap: 16px;
    padding: 24px;
    background-color: $background-80;
    @extend %flex-column;

    @media (max-width: $tablet-m) {
      padding: 16px;
    }

    &__buttons {
      gap: 16px;
      display: flex;
    }
  }

  &__row {
    gap: 16px;
    @extend %flex-align;
  }

  &__google {
    border: 1px solid $secondary-1100;
    background-color: $background-70;
    color: $secondary-1600;

    &:hover {
      color: $secondary-1600;
      background-color: $background-80;
    }

    &:focus {
      color: $secondary-1600;
      background-color: $background-80;
    }
  }

  &__form {
    gap: 16px;
    padding: 0 32px;
    @extend %flex-column;

    @media (max-width: $tablet-m) {
      padding: 0 16px;
    }
  }

  &__have_account {
    gap: 8px;
    padding: 0 32px;
    @extend %flex-align;

    @media (max-width: $tablet-m) {
      padding: 0 16px;
    }

    &__text {
      color: $secondary-1500;
      @extend %body1-r;

      @media (max-width: $tablet-m) {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  &__select {
    gap: 4px;
    width: 100%;
    @extend %flex-column;

    &__title {
      color: $secondary-1600;
      @extend %body1-r;

      @media (max-width: $tablet-m) {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }

    &__birthday {
      display: flex;
      flex: 1 1 1;
      gap: 16px;
    }

    &__gender {
      gap: 16px;
      flex-wrap: nowrap !important;
      flex-direction: row !important;
      @extend %flex-align-between;

      @media (max-width: $tablet-m) {
        gap: 8px;
        flex-wrap: wrap !important;
      }

      & > label > span {
        white-space: nowrap;

        @media (max-width: $tablet-m) {
          white-space: normal;
        }
      }
    }
  }

  &__validation_container {
    gap: 8px;
    @extend %flex-column;

    &__row {
      gap: 4px;
      @extend %flex-align;
      @include svgFill($success, 16px);
    }

    &__passive {
      width: 16px;
      height: 16px;
      border: 1px solid $secondary-1300;
      border-radius: 50%;
    }

    &__text {
      color: $secondary-1600;
      @extend %micro-lt;

      &__active {
        color: $primary-1900;
        @extend %micro-r;
      }
    }
  }

  &__email {
    width: 48.5%;

    @media (max-width: $tablet-m) {
      width: 100%;
    }
  }
}

@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

.item {
  gap: 4px;
  display: flex;
  text-wrap: nowrap;

  &_time {
    @extend %micro-sb;
    color: $secondary-1700;
  }

  @include svgSize('16px');

  &_increment {
    stroke: $success-75;
    transform: rotate(0deg);
  }

  &_decrement {
    stroke: $error-75;
    transform: rotate(70deg);
  }

  &_percent {
    @extend %micro-r;
    color: $secondary-1500;

    &_increment {
      color: $success-75;
    }

    &_decrement {
      color: $error-75;
    }
  }
}

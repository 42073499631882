@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

.badge {
  height: 24px;
  border-radius: 24px;
  color: $success-75;
  text-transform: uppercase;
  padding: 4px 12px;
  background-color: $success-150;
  @extend %micro-sb;
  @extend %flex-center;

  &__sell {
    color: $error-50;
    background-color: $error-175;
  }
}

.dot {
  border-radius: 50%;
  background-color: $success-75;
  @include minMaxSize(16px, 16px);

  &__sell {
    background-color: $error-50;
  }

  &__text {
    text-transform: uppercase;
    color: $success-75;
    @extend %secondary-heading;

    &__sell {
      color: $error-50;
    }
  }

  &__container {
    gap: 8px;
    @extend %flex-align;
  }
}

@import 'styles/mixins';
@import 'styles/vars';

/** React Swiper */

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  cursor: pointer;
  border-radius: 16px;
  transition: all 0.6s;
  opacity: 0.2;
  background-color: $primary-1500;
}

.swiper-pagination-bullet-active {
  width: 32px;
  opacity: 1;
}

.swiper-slide {
  height: auto;
}

/** React alice carousel */
.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  padding: 0;
  display: block;
}

.alice-carousel__wrapper {
  border-radius: 16px;
}

.alice-carousel__stage {
  display: flex;
}
.alice-carousel__stage-item {
  flex: 1 0 auto;
  height: auto;
}

/** MUI { */
/**    DatePicker { */
.MuiPickersCalendarHeader-root,
.MuiDayCalendar-header {
  display: none !important;
}

.MuiDateCalendar-root {
  height: auto !important;
}

.MuiStack-root {
  width: 100%;
  flex-direction: unset !important;
  justify-content: space-between;
}

.MuiYearCalendar-root {
  @include scrollbar(4px);
}

.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid $primary-300 !important;
}

/** Skeleton **/

.MuiSkeleton-root {
  transform: unset !important;
}

.ReactCrop__crop-selection {
  height: 100%;
}

.ReactCrop__image {
  height: 100%;
  max-height: 480px;

  @media (max-width: $tablet-m) {
    max-height: 320px;
  }
}

.skeleton {
  &__dark {
    background-color: $skeleton-dark !important;
  }

  &__light {
    background-color: $skeleton-light !important;
  }
}

//CSS Transition Group

.step_back-enter {
  animation: fade-in-back 600ms 1;
}

.step_back-exit {
  animation: fade-out-back 600ms 1;
}

.step_forward-enter {
  animation: fade-out-forward 600ms 1;
}

.step_forward-exit {
  animation: fade-in-forward 600ms 1;
}

@keyframes fade-in-back {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  25% {
    opacity: 0.1;
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fade-out-back {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  25% {
    opacity: 0.1;
  }

  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

@keyframes fade-out-forward {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  25% {
    opacity: 0.1;
  }

  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

@keyframes fade-in-forward {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  25% {
    opacity: 0.1;
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.cookie_banner-enter {
  transform: translate(-50%, 150%);
}

.cookie_banner-enter-active {
  transition: all 1.2s;
  transform: translate(-50%, 0);
}

.cookie_banner-exit {
  transform: translate(-50%, 0);
}

.cookie_banner-exit-active {
  transition: all 0.6s;
  transform: translate(-50%, 200%);
}

.swiper-slide-shadow-left {
  display: none !important;
}

.swiper-slide-shadow-right {
  display: none !important;
}

//lite yt embed

.yt-lite {
  width: 100%;
  cursor: pointer;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: calc(80vh - 132px);
  @extend %flex-center;
}

/* gradient */
.yt-lite::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  background-position: top;
  background-repeat: repeat-x;
  height: 60px;
  padding-bottom: 50px;
  width: 100%;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}

/* responsive iframe with a 16:9 aspect ratio
  thanks https://css-tricks.com/responsive-iframes/
*/
.yt-lite::after {
  content: '';
  display: block;
  padding-bottom: calc(100% / (16 / 9));
}
.yt-lite > iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

/* play button */
.yt-lite > .lty-playbtn {
  width: 65px;
  height: 46px;
  z-index: 1;
  opacity: 0.8;
  border: none;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}
.yt-lite:hover > .lty-playbtn {
  opacity: 1;
}
/* play button triangle */
.yt-lite > .lty-playbtn:before {
  content: '';
  border-style: solid;
  border-width: 11px 0 11px 19px;
  border-color: transparent transparent transparent #fff;
}

.yt-lite > .lty-playbtn,
.yt-lite > .lty-playbtn:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

/* Post-click styles */
.yt-lite.lyt-activated {
  cursor: unset;
}
.yt-lite.lyt-activated::before,
.yt-lite.lyt-activated > .lty-playbtn {
  opacity: 0;
  pointer-events: none;
}

@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

.wrapper {
  gap: 4px;
  width: 100%;
  height: auto;
  position: relative;
  @extend %flex-column;

  &__arrow {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    transition: all 0.3s;

    &__flipped {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  &__label {
    position: absolute;
    top: 50%;
    transition: all 0.3s;
    left: 8px;
    padding-left: 6px;
    padding-right: 6px;
    transform: translateY(-50%);
    font-size: 16px;
    color: $primary-1900;
    background-color: $background-80;
    transform-origin: top left;
    @extend %body2-lt;

    &__active {
      background-color: $background-90;
      transform: translateY(-200%);
      scale: 0.75;
    }
  }

  &__dropdown {
    position: absolute;
    border-radius: 8px;
    padding: 8px;
    z-index: 2;
    background-color: $white;
    color: rgba(0, 0, 0, 0.87);
    top: 40px;
    gap: 8px;
    width: 275px;
    max-height: 275px;
    display: none;
    grid-template-columns: repeat(6, 1fr);

    @media (max-width: $desktop) {
      gap: 2px;
      width: 235px;
    }

    &__dropped {
      display: grid;
    }

    &__item {
      padding: 6px;
      border-radius: 50%;
      border: 1px solid transparent;
      transition: all 0.3s;
      cursor: pointer;
      @extend %flex-center;

      @media (max-width: $desktop) {
        padding: 4px;
      }

      &:hover {
        border-color: $primary-10;
      }

      &__active {
        border-color: $primary-10;
      }

      &__disabled {
        opacity: 0.7;
        cursor: default;

        &:hover {
          border-color: transparent;
        }
      }
    }
  }
}

@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

.wrapper {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 997;
  padding: 28px;
  position: fixed;

  // @TODO Don't use %vars for "flex", creates duplication in css
  display: flex;
  align-items: center;
  justify-content: center;

  &__bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 998;
    position: fixed;
    background: $background;
  }

  &__content {
    width: 100%;
    z-index: 999;
    max-width: 456px;
    max-height: 80vh;
    overflow: hidden;
    border-radius: 8px;
    position: relative;
    background-color: $background-90;

    &__container {
      padding: 0 32px;
    }

    &__icon {
      top: 24px;
      right: 32px;
      z-index: 1;
      cursor: pointer;
      position: absolute;
      @include svgFill($secondary-1400, 20px);

      @media (max-width: $tablet) {
        right: 16px;
        top: 16px;
      }
    }

    &__title {
      width: 100%;
      padding: 32px;
      color: $primary-1900;
      word-break: break-all;

      @extend %heading-4;

      @media (max-width: $tablet) {
        padding: 16px;
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
      }

      &__closable {
        width: calc(100% - 104px);
      }
    }
  }
}

@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

$bg: '../../../../assets/images/blog/academy.png';

.wrapper {
  gap: 64px;
  width: 484px;
  padding: 20px;
  overflow: hidden;
  cursor: pointer;
  border-radius: 16px;
  transition: 0.6s all;
  background-image: url($bg);
  border: 1px solid transparent;
  @extend %flex-justify-between-center;

  @media (max-width: $desktop) {
    gap: 32px;
    position: relative;
  }

  &:hover {
    border-color: $primary-1400;

    .wrapper__right {
      opacity: 1;
      scale: 1.15;
      @include svgFill($primary-1400);
    }
  }

  &__icons {
    gap: 12px;
    @extend %flex-align;
  }

  &__left {
    gap: 8px;
    @extend %flex-column;

    &__container {
      gap: 24px;
      height: 100%;
      @extend %flex-column-between;
    }

    &__footer {
      gap: 20px;
      @extend %flex-align;
    }

    &__button {
      max-width: max-content;
    }
  }

  &__right {
    opacity: 0.4;
    transition: 0.6s all;
    margin-right: -80px;
    @include svgFill($primary-1800, 166px);

    @media (max-width: $tablet) {
      margin-right: 0;
      position: absolute;
      right: -80px;
    }

    > svg {
      transition: 0.6s all;
    }
  }

  &__description {
    gap: 4px;
    @extend %flex-column;

    &__title {
      color: $primary-1900;
      @extend %highlight-text;
    }

    &__subtitle {
      color: $secondary-1600;
      @extend %body2-r;
    }
  }
}

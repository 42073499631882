@import 'styles/vars';
@import 'styles/colors';
@import 'styles/typography';

.wrapper {
  gap: 16px;
  cursor: pointer;
  padding: 7px 15px;
  flex: 1 1;
  border-radius: 160px;
  transition: 0.6s all;
  background-color: $secondary-400;
  border: 1px solid transparent;
  @extend %flex-justify-between-center;

  &:hover {
    border-color: $primary-10;
    background-color: $secondary-100;
    box-shadow: 0 4px 20px 0 #01040673;
  }

  &__container {
    gap: 12px;
    @extend %flex-align;

    &__description {
      gap: 8px;
      @extend %flex-column;
    }
  }

  &__coin_name {
    color: $primary-1900;
    @extend %subheading-r;
  }

  &__percent {
    color: $primary-1600;
    @extend %subheading-m;
  }

  &__color {
    min-width: 16px;
    max-width: 16px;
    min-height: 16px;
    max-height: 16px;
    border-radius: 50%;
  }
}

@import 'styles/vars';
@import 'styles/colors';

.wrapper {
  padding: 0 16px;

  &__inner {
    @extend %flex-column;
    gap: 15px;

    &__button {
      white-space: nowrap;
      overflow: hidden;
      padding: 12px !important;

      &__amatik {
        animation: pulse 4s infinite;
      }
    }
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 6px 2px transparent, 0 0 0 0 transparent, 0 0 0 0 transparent;
  }

  50% {
    box-shadow: 0 0 6px 2px $mode-switch-shadow, 0 0 12px 4px transparent, 0 0 12px 5px $mode-switch-shadow;
  }

  100% {
    box-shadow: 0 0 6px 2px transparent, 0 0 0 9px transparent, 0 0 0 9px transparent;
  }
}

@import 'styles/vars';
@import 'styles/mixins';
@import 'styles/colors';
@import 'styles/typography';

.container {
  gap: 32px;
  max-width: 684px;
  border-radius: 8px;
  padding: 24px 32px 32px;
  background: $background-80;
  @extend %flex-column-center;
  border: 1px solid $secondary-1100;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);

  @media (max-width: $tablet-w) {
    gap: 16px;
    padding: 16px;
    max-width: 294px;
  }

  &__icon {
    @include svgSize(88px);

    @media (max-width: $tablet-w) {
      @include svgSize(48px);
    }

    &__error {
      @include svgFill($error);
    }
  }

  &__content {
    text-align: center;

    &__title {
      color: $primary-1900;
      @extend %heading-2;

      @media (max-width: $tablet-w) {
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
      }
    }

    &__text {
      margin-top: 8px;
      color: $secondary-1600;
      @extend %body2-r;
    }
  }

  &__links {
    gap: 24px;
    width: 100%;
    @extend %flex-align;

    @media (max-width: $tablet-w) {
      gap: 16px;
      flex-direction: column;
    }

    &__btn {
      padding: 12px 32px;
      @extend %button-text;
    }
  }
}

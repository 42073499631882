@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

.wrapper {
  gap: 24px;
  padding-top: 24px;
  @extend %flex-column;

  &__column {
    gap: 16px;
    @extend %flex-column;
  }

  &__row {
    gap: 16px;
    @extend %flex-align;
  }

  &__form {
    gap: 24px;
    @extend %flex-column;

    @media (max-width: $tablet) {
      height: 100%;
      justify-content: space-between;
    }

    &__forgot {
      cursor: pointer;
      color: $primary-10;
      white-space: nowrap;
      text-transform: capitalize;
      @extend %button-text;

      @media (max-width: $tablet-m) {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
      }
    }

    &__content {
      gap: 24px;
      padding: 0 32px;
      @extend %flex-column;

      @media (max-width: $tablet-m) {
        padding: 0 16px;
      }
    }
  }

  &__buttons {
    gap: 16px;
    height: 100%;
    padding: 24px;
    background-color: $background-80;
    @extend %flex-column;

    @media (max-width: $tablet) {
      height: max-content;
    }

    @media (max-width: $tablet-m) {
      gap: 8px;
      padding: 16px;
    }

    &__google {
      border: 1px solid $secondary-1100;
      background-color: $background-70;
      color: $secondary-1600;

      &:hover {
        color: $secondary-1600;
        background-color: $background-80;
      }

      &:focus {
        color: $secondary-1600;
        background-color: $background-80;
      }
    }

    &__auth {
      flex-direction: column-reverse;
    }

    &__wallet {
      @media (max-width: $tablet-m) {
        padding: 9px 32px;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        max-height: 40px;
        @include svgSize(20px, 20px);
      }
    }
  }
}

.wallet {
  @extend %flex-center;

  &__item {
    @extend %flex-align;
    gap: 12px;

    &_icon {
      max-width: 24px;
      max-height: 24px;
    }

    &__coin_name {
      @extend %body2-lt;
      color: $secondary-1600;
    }

    &_balance {
      @extend %body2-sb;
      color: $primary-10;
      white-space: nowrap;
    }

    &_count {
      @extend %micro-sb;
      color: $primary-1900;
      white-space: nowrap;
    }

    &__hr {
      width: 1px;
      height: 24px;
      border: 0.5px solid $secondary-1100;
      margin: 0 24px;
    }
  }
}

.disconnect {
  color: $error;
  margin-top: 0;
  @include svgFill($error);

  &:hover {
    color: $error-75;
    @include svgFill($error-75);
    background-color: transparent;
  }
}

@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

.wrapper {
  gap: 16px;
  @extend %flex-align;

  &__divider {
    width: 100%;
    height: 1px;
    background-color: $secondary-1100;

    &__text {
      color: $secondary-1500;
      @extend %micro-sb;
      @extend %flex-align;
      @include svgFill($secondary-1500);
      @include svgSize(24px, 24px);
    }
  }
}

@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

.level {
  width: 100%;
  max-width: 116px;
  display: flex;
  @include svgSize(16px, 16px);

  &__number {
    color: $secondary-1700;
    @extend %micro-lt;
  }
}

.active path {
  fill: $yellow;
}

@import 'styles/vars';
@import 'styles/mixins';
@import 'styles/colors';
@import 'styles/typography';

$background: '../../../assets/images/error/errorsBg.png';

.wrapper {
  @include pageLayout();

  @media (max-width: $desktop) {
    display: flex;
    justify-content: center;
    height: calc(100vh - 152px);
  }

  @media (max-height: 833px) and (max-width: $desktop) {
    height: auto;
    align-items: center;
  }

  &__container {
    background: url($background);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 16px;
    width: 100%;
    height: 100%;
    @extend %flex-center;
    gap: 64px;
    padding: 16px;

    @media (max-width: $desktop) {
      flex-direction: column;
      gap: 32px;
      height: max-content;
      padding: 64px 16px;
    }

    @media (max-width: $desktop) and (min-height: 833px) {
      max-height: 788px;
      height: calc(100vh - 172px);
    }

    @media (max-width: $tablet) {
      padding: 32px 16px;
      gap: 0;
    }

    &__icon {
      min-width: 360px;
      min-height: 360px;

      @media (max-width: $desktop) {
        min-width: 200px;
        min-height: 200px;
        max-width: 200px;
        max-height: 200px;
      }
    }

    &__title {
      @extend %display-title-l;
      color: transparent;
      -webkit-text-stroke: 3px $primary-10;

      @media (max-width: $desktop) {
        font-weight: 700;
        font-size: 96px;
        line-height: 120px;
        text-align: center;
      }

      @media (max-width: $tablet) {
        -webkit-text-stroke: 1px $primary-10;
        font-size: 48px;
        font-weight: 700;
        line-height: 72px;
      }
    }

    &__text {
      color: $primary-1800;
      @extend %heading-macro;
      margin-bottom: 8px;

      @media (max-width: $desktop) {
        text-align: center;
      }

      @media (max-width: $tablet) {
        font-size: 28px;
        font-weight: 700;
        line-height: 40px;
      }
    }

    &__subtitle {
      color: $secondary-1600;
      @extend %body1-lt;

      @media (max-width: $desktop) {
        text-align: center;
      }
    }

    &__buttons {
      display: flex;
      gap: 24px;
      margin-top: 32px;

      @media (max-width: $desktop) {
        justify-content: center;
      }

      @media (max-width: $tablet) {
        flex-direction: column;
        margin-top: 16px;

        button {
          max-width: unset;
        }
      }

      &__back {
        max-width: 184px;
      }

      &__overview {
        max-width: 214px;
      }
    }

    &__reload {
      margin-top: 32px;
      max-width: 150px;

      @media (max-width: $desktop) {
        margin: 32px auto 0;
      }

      @media (max-width: $tablet) {
        max-width: unset;
      }
    }
  }
}

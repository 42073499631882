@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

.link {
  cursor: pointer;
  border-radius: 16px !important;
  color: $secondary-1600 !important;
  background: $background-80;
  border: 1px solid $background-70;
  transition: all 0.6s;
  text-decoration: none !important;
  padding: 3px 8px;
  min-height: 24px;
  max-height: 24px;
  @extend %micro-r;
  line-height: 0 !important;
  @include svgSize(16px, 16px !important);

  &__filled {
    @include svgFill($secondary-1600);

    &:hover {
      @include svgFill($primary-1900);
    }
  }

  &:hover {
    color: $primary-1900;
    border-color: $secondary-1200;
    background-color: $background-90;
  }
}

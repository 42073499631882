@import 'styles/colors';
@import 'styles/typography';
@import 'styles/mixins';

.wrapper {
  width: 100%;
  gap: 8px;
  @extend %flex-column;

  &__error {
    top: 4px;
    right: 4px;
    top: 4px;
    right: 4px;
    position: absolute;
    @include svgFill($error);

    &__large {
      top: 8px;
      right: 8px;
    }

    &__large {
      top: 8px;
      right: 8px;
    }
  }

  &__label {
    color: $secondary-1500;
    color: $secondary-1500;
    @extend %body2-r;

    &__large {
      @extend %body1-r;
    }

    &__disabled {
      color: $secondary-1200;
    }
  }

  &__container {
    width: 100%;
    position: relative;
    height: 88px;

    &__large {
      height: 120px;
      width: 100%;
    }
  }

  &__textarea {
    width: 100%;
    height: 100%;
    list-style-type: none;
    color: $primary-1900;
    border-radius: 5px;
    padding: 10px 32px 10px 10px;
    outline: none;
    border: 1px solid transparent;
    @extend %body1-lt;
    @include scrollbar(4px, 4px);
    background: $background-80;
    transition: 0.3s all;
    resize: none;

    &__error:focus {
      border-color: $error !important;
    }

    &__large {
      @extend %body1-lt;

      &::placeholder {
        @extend %body2-lt;
      }
    }

    &__disabled {
      border: none;

      &:hover {
        border: none;
      }

      &::placeholder {
        color: $secondary-1200;
      }

      &::placeholder {
        color: $secondary-1200;
      }
    }

    &:hover {
      border-color: $secondary-1400;
    }

    &:disabled {
      opacity: 0.5;
    }

    &::placeholder {
      color: $secondary-1400;
      font-family: Rubik;
      @extend %body1-lt;
    }

    &:focus {
      background: $background-90;
      border-color: $primary-300;
    }
  }
}

@import 'styles/vars';

$wide-l: 1440px;
$wide-s: 1096px;

.wrapper {
  gap: 32px;
  @extend %flex-center;

  @media (max-width: $wide-s) {
    gap: 12px;
  }

  @media (max-width: $tablet) {
    gap: 8px;
  }
}

.pre_sale {
  gap: 16px;
  z-index: 1;

  @media (max-width: $tablet) {
    gap: 8px;
  }
}

@import './vars';

@mixin svgFill($color, $size: false) {
  & > svg {
    @if $size {
      width: $size;
      height: $size;
    }

    path {
      transition: all 0.5s;
      fill: $color;
      @content;
    }
  }
}

@mixin svgSize($size, $minMaxSize: false) {
  svg {
    @content;

    @if $minMaxSize {
      min-width: $minMaxSize;
      min-height: $minMaxSize;
      max-width: $minMaxSize;
      max-height: $minMaxSize;
    } @else {
      width: $size;
      height: $size;
    }
  }
}

@mixin scrollbar($width, $isVisibleHighResolution: false, $height: false) {
  // WebKit browsers (Chrome, Safari)
  &::-webkit-scrollbar {
    width: $width;
    @content;

    @if $height {
      height: $height;
    }

    @if ($isVisibleHighResolution == false) {
      @media (min-height: $scrollHeight) {
        width: 0;
      }
    }
  }

  &::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: $secondary-1100;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: $primary-200;

    &:hover {
      background-color: $primary-1200;
    }
  }

  // Firefox fallback
  @supports (-moz-appearance: none) {
    scrollbar-width: $width;
    scrollbar-color: $primary-200 $secondary-1100;

    @if $height {
      scrollbar-height: $height;
    }

    @if ($isVisibleHighResolution == false) {
      @media (min-height: $scrollHeight) {
        scrollbar-width: none;
      }
    }
  }
}

@mixin scrollbarHorizontal($height) {
  &::-webkit-scrollbar {
    height: $height;

    @content;
  }

  &::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: $secondary-1100;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: $primary-200;

    &:hover {
      background-color: $primary-1200;
    }
  }
}

@mixin pageScroll($scrollBarWidth, $height, $max-height, $isVisibleHighResolution: false) {
  height: $height;
  overflow-y: auto;
  max-height: $max-height;
  scrollbar-gutter: stable;
  @include scrollbar($scrollBarWidth, $isVisibleHighResolution);

  @if $isVisibleHighResolution {
    margin-right: -16px;
    padding-right: 12px;
  }

  @if ($isVisibleHighResolution) {
    @media (max-height: $scrollHeight) {
      margin-right: -16px;
      padding-right: 12px;
    }
  }
}

@mixin pageLayout($isVisibleHighResolution: false) {
  overflow-y: auto;
  max-height: 788px;
  height: calc(100vh - 152px);
  scrollbar-gutter: stable;

  @if ($isVisibleHighResolution) {
    margin-right: -16px;
    padding-right: 12px;
  }

  @media (max-width: $desktop) {
    height: auto;
    width: 100%;
    margin-top: 66px;
    padding: 16px 16px 80px;
    max-height: unset;
    overflow-y: unset;
    @include scrollbar(0);
  }
}

@mixin blueBorderedSocialLinks() {
  width: 32px;
  height: 32px;
  padding: 6px;
  border-radius: 50%;
  border: 1px solid $social-links;
  transition: 0.2s all ease-in-out;
  @extend %flex-center;
  @include svgFill($social-links);
  @include svgSize(20px, 20px);

  &:hover {
    background-color: $social-links-hover;
  }
}

@mixin spinningAnimation($iterationCount, $duration, $reverse: false) {
  @if $reverse {
    animation: spinning reverse $duration linear $iterationCount;
  } @else {
    animation: spinning $duration linear $iterationCount;
  }

  @keyframes spinning {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

@mixin minMaxSize($width: false, $height: false) {
  @if ($height and $width == false) {
    min-height: $height;
    max-height: $height;
  }

  @if (($height == false and $width)) {
    min-width: $width;
    max-width: $width;
  }

  @if ($width and $height) {
    min-height: $height;
    max-height: $height;
    min-width: $width;
    max-width: $width;
  }
}

@mixin inputStyles() {
  outline: none;
  padding: 0 8px 0 12px;
  border-radius: 8px;
  transition: 0.6s all;
  box-sizing: border-box;
  background-color: $background-80;
  border: 1px solid transparent;
  color: $primary-1900 !important;
  @extend %body1-lt;

  &::placeholder {
    color: $secondary-1400;
  }

  &:disabled {
    opacity: 0.5;
  }

  &:hover {
    border-color: $secondary-1400;
  }

  &:focus {
    border-color: $primary-300;
    background-color: $background-90;
  }
}

@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

.wrapper {
  gap: 24px;
  overflow: hidden;
  position: relative;
  @extend %flex-align;

  &__dots {
    gap: 4px;
    width: 8px;
    transition: all 0.6s;
    height: 32px;
    @extend %flex-column;

    &__not_max {
      height: 24px;
      justify-content: center;
    }
  }

  &__item {
    cursor: pointer;
    width: 6px;
    min-height: 6px;
    max-height: 6px;
    border-radius: 50%;
    transition: 0.6s all;
    border: 1px solid transparent;
    background-color: $secondary-1100;

    &__active {
      scale: 1.3;
      background-color: transparent;
      border-color: $primary-1400;
    }
  }

  &__content {
    opacity: 0;
    position: absolute;
    transition: 0.6s transform, 0.6s opacity;
    transform: translateY(100%);

    &__text {
      gap: 8px;
      color: $secondary-1800;
      @extend %body1-r;
      @extend %flex-align;
      @include svgSize(24px);
    }

    &__active {
      opacity: 1;
      position: static;
      transform: translateY(0);
    }
  }
}

@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

.wrapper {
  width: 217px;
  padding: 20px 48px;
  border-radius: 16px;
  background-color: $primary-800;
  @extend %flex-column-center;

  @media (max-width: $desktop) {
    min-width: 142px;
    max-width: 142px;
    padding: 48px 12px;
  }

  @media (max-width: $tablet) {
    min-width: 76px;
    max-width: 76px;
    padding: 12px 16px;
  }

  @media (max-width: $mobile-m) {
    min-width: 65px;
    max-width: 65px;
  }

  &:first-child {
    width: 167px;

    @media (max-width: $desktop) {
      min-width: 142px;
      max-width: 142px;
    }

    @media (max-width: $tablet) {
      min-width: 76px;
      max-width: 76px;
    }

    @media (max-width: $mobile-m) {
      min-width: 45px;
      max-width: 45px;
    }
  }

  &__date {
    color: transparent;
    -webkit-text-stroke: 3px $primary-10;
    text-align: center;
    @extend %heading-macro-700;

    @media (max-width: $desktop) {
      font-weight: 700;
      font-size: 48px;
      line-height: 72px;
    }

    @media (max-width: $tablet) {
      font-size: 32px;
      font-weight: 700;
      line-height: 48px;
      -webkit-text-stroke: 1px $primary-10;
    }

    @media (max-width: $mobile) {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__name {
    text-align: center;
    color: $primary-1500;
    text-transform: uppercase;
    @extend %subheading-r;

    @media (max-width: $desktop) {
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
    }

    @media (max-width: $tablet) {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.pre_sale {
  width: 156px;
  padding: 12px 32px;

  @media (max-width: $tablet) {
    padding: 12px 16px;
  }

  @media (max-width: $mobile-l) {
    width: 77.5px !important;
    padding: 6px 12px;
  }

  &:nth-child(1) {
    width: 115px;
  }

  &:nth-child(2) {
    width: 132px;
  }

  &:nth-child(3) {
    width: 150px;
  }

  &__date {
    @extend %heading-macro;

    @media (max-width: $tablet) {
      font-size: 32px;
      font-weight: 700;
      line-height: 48px;
    }
  }
}

@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

.wrapper {
  gap: 20px;
  width: 100%;
  cursor: pointer;
  min-width: 274px;
  position: relative;
  border-radius: 16px;
  padding-bottom: 8px;
  transition: 0.6s all;
  border: 1px solid transparent;
  background-color: $secondary-500;
  @extend %flex-column;

  &:hover {
    border-color: $secondary-100;
    background-color: $secondary-200;
  }

  &__image__container {
    padding: 16px 16px 0;
    height: 136px;
  }

  &__container {
    padding: 0 16px;
  }
}

@import 'styles/vars';
@import 'styles/mixins';
@import 'styles/colors';
@import 'styles/typography';

.wrapper {
  gap: 16px;
  left: 50%;
  z-index: 2;
  width: 100%;
  bottom: 32px;
  padding: 16px;
  position: fixed;
  max-width: 389px;
  border-radius: 16px;
  transform: translateX(-50%);
  background-color: $background-80;
  border: 1px solid $primary-10;
  @extend %flex-column;

  @media (max-width: $desktop) {
    bottom: 108px;
    max-width: unset;
    width: calc(100% - 32px);
  }

  &__head {
    gap: 4px;
    @extend %flex-column;
  }

  &__title {
    gap: 4px;
    color: $primary-1700;
    @extend %flex-align;
    @extend %subheading-b;
    @include svgSize(32px);
  }

  &__link {
    color: $primary-10;
    @extend %micro-sb;
  }

  &__subtitle {
    color: $secondary-1700;
    @extend %micro-lt;
  }

  &__footer {
    gap: 16px;
    width: 100%;
    justify-content: flex-end;
    @extend %flex-align;

    &__button {
      max-width: max-content;
    }
  }
}

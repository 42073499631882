@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

input[type='date']::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.wrapper {
  width: 100%;
  position: relative;

  &__container {
    position: relative;

    &__tooltip {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 36px;
    }
  }

  &__label {
    color: $secondary-1500;
    box-sizing: border-box;
    margin-bottom: 4px;
    @extend %body1-r;
  }

  &__field {
    width: 100% !important;
    position: relative;
    border-radius: 8px;
    background: $background-80;

    &__error fieldset {
      border-color: $error !important;
    }

    input {
      @extend %flex-align;
      width: 100% !important;
      height: 48px;
      outline: none;
      padding: 0 12px;
      font-size: 16px;
      @extend %body2-lt;
      border-radius: 8px;
      transition: 0.3s all;
      box-sizing: border-box;
      background: $background-80;
      border: 1px solid transparent;
      color: $primary-1900 !important;
    }
  }
}

@import 'styles/vars';
@import 'styles/mixins';
@import 'styles/colors';
@import 'styles/typography';

.wrapper {
  width: 100%;
  height: 8px;
  border-radius: 4px;

  &_blue {
    background-color: $background-80;
  }

  &_green {
    background-color: $success-75;
  }

  &__inner {
    height: 100%;
    border-radius: 4px;
    transition: width 1s;

    &_blue {
      background-color: $primary-1200;
    }
    &_green {
      background-color: $success-75;
    }
  }
}

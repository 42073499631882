@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

.wrapper {
  width: 400px;
  height: 100%;
  position: relative;
  padding: 8px;
  transform: translateX(-58%);
  @extend %flex-center;

  @media (max-width: 1400px) {
    height: auto;
    position: absolute;
    bottom: -50px;
    left: 50%;
    transform: translate(-50%, 36%);
  }

  @media (max-width: $tablet) {
    transform: translate(-50%, 36%) scale(0.7);
  }

  &__logo {
    @include spinningAnimation(120s, infinite);
    @include svgFill($main-spinner, 282px);

    &__faster {
      @include spinningAnimation(1s, 1);
    }

    &__reverse {
      @include spinningAnimation(1s, 1, reverse);
    }
  }

  &__text {
    width: 100%;
    height: 500px;
    @extend %micro-r;
    user-select: none;
    position: absolute;
    color: $main-spinner-text;
    @extend %flex-column-center;
    @include spinningAnimation(120s, infinite);

    &__item {
      @extend %flex-justify-between;

      p {
        @include spinningAnimation(120s, infinite, reverse);
      }
    }

    &__1 {
      width: 200px;
    }

    &__2 {
      width: 380px;
      margin: 35px 0;
    }

    &__3 {
      width: 470px;
      margin: 25px 0;
    }

    &__4 {
      width: 510px;
      margin: 50px 0;
    }
  }
}

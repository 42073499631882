// circular progressbar
@import 'react-circular-progressbar/dist/styles.css';
@import '~react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';
// Carousel global styles
@import 'react-alice-carousel/lib/scss/alice-carousel.scss';

// Local css base
@import './colors.scss';
@import './fonts.scss';
@import './local.scss';
@import './mixins.scss';
@import './typography.scss';
@import './vars.scss';
@import './lib.scss';
@import './themeColors.scss';

.auth_modal_title {
  gap: 8px;
  padding: 24px 32px 16px;
  border-bottom: 1px solid $background-70;
  @extend %flex-column;

  @media (max-width: $desktop) {
    padding: 16px;
  }

  &__container {
    gap: 16px;
    @include svgFill($secondary-1400, 20px);
    @extend %flex-align-between;

    &__text {
      color: $primary-1900;
      @extend %heading-4;

      @media (max-width: $desktop) {
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
      }
    }
  }

  &__subtitle {
    color: $secondary-1600;
    @extend %body2-lt;

    @media (max-width: $desktop) {
      font-size: 12px;
      font-weight: 300;
      line-height: 18px;
    }
  }
}

.text_link {
  cursor: pointer;
  color: $primary-10;
  white-space: nowrap;
  @extend %button-text;

  @media (max-width: $desktop) {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
}

@import 'styles/vars';
@import 'styles/mixins';
@import 'styles/colors';
@import 'styles/typography';

.container {
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  width: 272px;
  height: 100dvh;
  position: fixed;
  user-select: none;
  padding: 16px 0 50px;
  transition: transform 1s;
  border-radius: 16px 0 0 16px;
  background-color: $header-bg;
  @extend %flex-column-between;

  &_closed {
    transform: translateX(100%);
  }

  &__wrapper {
    gap: 15px;
    @extend %flex-column;
  }

  &__header {
    padding: 0 16px;
    gap: 16px;
    @extend %flex-column;

    &__lang {
      width: 100%;
      @extend %flex-justify-between-center;
    }

    &__switch {
      gap: 8px;
      @extend %flex-column-center;

      &__title {
        color: $account-switch-title;
        @extend %micro-sb;
      }
    }

    &__auth {
      gap: 12px;
      padding: 0 16px;
      @extend %flex-column;

      &__myAcc_button {
        width: 100%;
        max-width: unset;
      }

      &__authenticated {
        gap: 12px;
        @extend %flex-align;
      }

      &__btn {
        max-width: 48px;
      }
    }
  }
}

@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/vars.scss';
@import 'styles/typography';

.wrapper {
  width: 48px;
  position: relative;
  color: $lang-drop;
  z-index: 2;
  @include svgFill($lang-drop);
  @extend %body1-r;

  @media (max-width: $desktop) {
    @include svgSize(20px);
  }

  &__text {
    gap: 4px;
    padding: 8px 0;
    cursor: pointer;
    user-select: none;
    @extend %flex-center;
  }

  &__dropdown {
    width: 72px;
    height: 112px;
    position: absolute;
    background-color: $header-bg;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    max-height: 0;
    transition: opacity 0.3s, max-height 0.3s, padding 0.3s;
    overflow: hidden;
    @extend %flex-column-center;

    &__item {
      padding: 0;
      max-height: 0;
      opacity: 0;
      color: $secondary-1500;
      cursor: pointer;
      transition: opacity 0.3s, max-height 0.3s, padding 0.3s;

      &:hover {
        background-color: $background-90;
        color: $primary-1900;
      }
    }
  }

  &__dropped {
    max-height: 112px;
    border: 1px solid $secondary-100;

    p {
      opacity: 1;
      width: 72px;
      padding: 24px 0;
      @extend %flex-center;
    }
  }

  &__active__lang {
    color: $primary-10;

    &:hover {
      color: $primary-10;
      background-color: unset;
    }
  }
}

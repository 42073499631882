@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

$mobile-m: 376px;

.wrapper {
  max-width: 747px;
  width: 100%;
  margin: 0 auto;
  z-index: 1;

  &__bottom {
    gap: 32px;
    @extend %flex-column;

    @media (max-width: $tablet) {
      gap: 16px;
    }
  }

  &__notified {
    gap: 24px;
    @extend %flex-center;

    @media (max-width: $desktop) {
      flex-direction: column;
      gap: 12px;
      margin-bottom: 12px;
    }

    &__text {
      color: $primary-1800;
      white-space: nowrap;
      @extend %flex-justify-center;
      @extend %heading-macro;

      @media (max-width: $tablet) {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        width: 100%;
      }

      @media (max-width: $mobile-m) {
        font-size: 20px;
        line-height: 28px;
      }
    }

    &__info {
      gap: 12px;
      padding: 12px 24px;
      border-radius: 8px;
      background-color: $primary-700;
      border: 1px solid $primary-600;
      @extend %flex-align;

      @include svgSize(48px) {
        @media (max-width: $tablet) {
          width: 32px;
          height: 32px;
        }
      }

      @media (max-width: $tablet) {
        gap: 8px;
        width: 100%;
        justify-content: center;
        padding: 7px 12px;
      }

      &__text {
        color: $primary-1700;
        text-transform: capitalize;
        @extend %heading-1;

        @media (max-width: $tablet) {
          font-size: 20px;
          font-weight: 700;
          line-height: 28px;
        }
      }
    }
  }

  &__coming {
    gap: 24px;
    margin: 4px 0 8px;
    @extend %flex-center;

    @media (max-width: $mobile-m) {
      gap: 8px;
    }

    &__info {
      gap: 8px;
      overflow: unset !important;
      padding: 8px 16px;
      max-width: 237px;
      margin: auto;
      @include svgSize(32px, 32px !important);

      @media (max-width: $tablet) {
        gap: 4px;
        width: 100% !important;
        max-width: unset;
        @include svgSize(24px, 24px !important);
      }

      > p {
        overflow: unset;
        white-space: nowrap;
        padding: 0;
        width: unset;
        @extend %heading-4;

        @media (max-width: $tablet) {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }

    &__text {
      color: $secondary-1600;
      padding: 0 12px;
      text-align: center;
      @extend %body1-lt;

      @media (max-width: $tablet) {
        padding: 0;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
      }
    }
  }
}

.free_lesson_text {
  @extend %heading-3;
}

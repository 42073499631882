@import 'styles/colors';
@import 'styles/vars';

.wrapper {
  position: relative;
  width: 128px;
  height: 128px;
  border-radius: 50%;
  background-color: $background-190;
  overflow: hidden;
  transition: all 0.3s;

  &:hover &__edit {
    background-color: rgba(0, 10, 17, 0.5);

    &__label {
      transition: all 0.3s;
      opacity: 1;
      cursor: pointer;
    }
  }

  &__image {
    top: -10px;
    object-fit: cover;
    position: absolute;
  }

  &__edit {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    border-radius: 50%;
    transition: all 1s;
    @extend %flex-center;

    &__input {
      display: none;
    }

    &__label {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      opacity: 0;
      transition: all 0.3s;
      @extend %flex-center;

      svg {
        width: 32px;
        height: 32px;
      }
    }
  }
}

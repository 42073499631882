@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

$bg: '../../../../assets/images/blog/academy.png';
$wide-m: 1366px;

.wrapper {
  gap: 64px;
  width: 484px;
  padding: 20px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  border-radius: 16px;
  transition: 0.6s all;
  background-image: url($bg);
  border: 1px solid transparent;

  @media (max-width: $wide-m) {
    width: auto;
    flex: 1;
  }

  &:hover {
    border-color: $primary-1400;
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    &_item {
      display: flex;
      text-align: center;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      min-height: 98px;
      border-right: 2px solid #ffffff1a;
      border-bottom: 2px solid #ffffff1a;

      &:nth-child(3n) {
        border-right: none;
      }

      &:nth-last-child(-n + 3) {
        border-bottom: none;
      }

      p {
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        color: #cfd4d9;
        max-width: 121px;
        margin-top: 8px;
      }

      svg {
        path {
          fill: #85ddb7;
        }
      }
    }
  }
}

.title {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  margin-bottom: 16px;
  color: #e6f4ff;
}

.icon {
  position: absolute;
  right: 0;
  top: calc(50% - 86px);

  path {
    fill: rgba(204, 234, 254, 0.1);
  }
}

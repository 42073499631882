@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

.wrapper {
  padding-bottom: 32px;

  &__loading {
    margin-top: 32px;
  }

  &__select {
    gap: 4px;
    width: 100%;
    @extend %flex-column;

    &__row {
      gap: 16px;
      @extend %flex-align;
    }

    &__title {
      color: $secondary-1600;
      @extend %body1-r;

      @media (max-width: $tablet-m) {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }

    &__birthday {
      display: flex;
      margin-bottom: 40px;
      flex: 1 1 1;
      gap: 16px;
    }
  }
}

.divider {
  margin: 20px 0;
}

@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

.container {
  padding: 2px;
  display: flex;
  cursor: pointer;
  user-select: none;
  border-radius: 8px;
  max-width: min-content;
  background: $secondary-1200;

  &__item {
    padding: 12px;
    display: block;
    @extend %body2-sb;
    border-radius: 8px;
    color: $secondary-1600;

    &_de_active {
      background: $background-80;
    }

    &_active {
      color: $primary-1900;
      background: $primary-100;
    }
  }
}
